import { render, staticRenderFns } from "./index.vue?vue&type=template&id=ff2b07a4&scoped=true"
var script = {}
import style0 from "./index.vue?vue&type=style&index=0&id=ff2b07a4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff2b07a4",
  null
  
)

export default component.exports